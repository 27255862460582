import { getUrlWithoutTrailingSlash } from "./getUrlWithoutTrailingSlash";

/**
 * Returns a url always ending with a single `/`.
 *
 * @param url {string} - The URL to be parsed.
 * @returns the original url with a trailing `/`.
 */
export function getUrlWithTrailingSlash(url: string): string {
  return `${getUrlWithoutTrailingSlash(url)}/`;
}
